import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Arrow from "./arrow";
import PointList from "./point-list";

const StyledCarousel = styled.div.attrs((props) => {
  return {
    "data-cy": `${props.cyData || ""}`,
    $breakpoint: props.$breakpoint ? props.$breakpoint : 768,
  };
})`
  .carousel-window {
    scroll-snap-type: ${(p) => (p.sticky ? "x mandatory" : "none")};
    scroll-snap-stop: ${(p) => (p.pagination ? "always" : "normal")};
    gap: ${(p) => p.slideSeparation}px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (min-width: ${(p) => p.$breakpoint}px) {
    .content-container {
      position: relative;

      .carousel-window {
        .carousel-item-wrapper {
          &.last {
            scroll-snap-align: start;
          }
        }
      }
    }
  }
`;

/**
 * Componente que permite representar un carrusel de imagenes o elementos.
 */
const Carousel = ({
  children,
  className,
  maxPointNum,
  slideSeparation,
  sticky,
  pagination,
  breakpoint,
  arrowWidth,
  pointsWidth,
  blurIntersection,
  blurColor,
  blurWidth,
  activeSlideId,
}) => {
  // STATE
  const [visibleSlides, setVisibleSlides] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [displayLeftArr, setDisplayLeftArr] = useState(false);
  const [displayRightArr, setDisplayRightArr] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  // REFS
  const carouselRef = useRef();
  const windowRef = useRef();

  const data = useRef({
    carousel: { rect: undefined, width: undefined },
    window: { rect: undefined, width: undefined },
    slides: [],
  });

  if (!children?.length) {
    return null;
  }

  // index 1
  const pointsNum = Math.ceil(children.length / visibleSlides);
  // index 0
  let activePoint = Math.ceil((currentSlide - 1) / visibleSlides);

  // apaño
  if (!displayRightArr) activePoint = pointsNum - 1;

  // GETTERS
  function getRectElements() {
    data.current.carousel.rect = carouselRef.current.getBoundingClientRect();
    data.current.window.rect = windowRef.current.getBoundingClientRect();

    data.current.carousel.width = carouselRef.current.scrollWidth;
    data.current.window.width = windowRef.current.scrollWidth;

    data.current.slides = [];

    for (let i = 0; i < windowRef.current.children.length; i++) {
      const elem = windowRef.current.children[i];

      if (elem.classList.contains("carousel-item-wrapper")) {
        data.current.slides.push(elem);
      }
    }

    // no se añade el primer y segundo elemento de espacios vacíos
    data.current.slides = data.current.slides.map((elem, i) => {
      const rect = elem.getBoundingClientRect();

      return {
        elem: elem,
        rect: rect,
        index: i + 1,
        width: elem.scrollWidth,
      };
    });
  }

  function getVisibleSlides() {
    let visibleSlides = 1;
    // ajusta en función de cuanto esté scroleado

    const scrollOffset = windowRef.current.scrollLeft - slideSeparation;
    const { slides, carousel } = data.current;

    slides.forEach((e, i) => {
      if (carousel.rect.right > e.rect.right + scrollOffset) {
        visibleSlides = i + 1;
      }
    });

    setVisibleSlides(visibleSlides);
  }

  function getCurrentSlide() {
    const { slides, carousel } = data.current;

    const currSlide = slides.reduce((minSlide, slide) => {
      const prevSlidePos = Math.abs(carousel.rect.left - minSlide.rect.left);
      const slidePos = Math.abs(carousel.rect.left - slide.rect.left);

      return prevSlidePos < slidePos ? minSlide : slide;
    });

    setCurrentSlide(currSlide.index);
  }

  function getIsMobile() {
    if (window) {
      if (window.innerWidth > breakpoint) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
  }

  // SETTERS
  function setScrollToSlide(i) {
    const { slides } = data.current;
    const slideNum = Math.min(Math.max(0, i), slides.length);

    if (slides[slideNum]) {
      const elem = slides[slideNum].elem;
      elem.parentNode.scroll({
        left: elem.offsetLeft,
        behavior: "smooth",
        inline: "start",
        block: "nearest",
      });
    }
  }

  function setDisplayArrows() {
    const { slides, window } = data.current;

    const lastElem = slides[slides.length - 1];
    const firstElem = slides[0];

    const rightOffset = Math.floor(lastElem.rect.right - window.rect.right);
    const leftOffset = Math.floor(window.rect.left - firstElem.rect.left);

    if (rightOffset <= 0) {
      setDisplayRightArr(false);
    } else {
      setDisplayRightArr(true);
    }

    if (leftOffset <= 0) {
      setDisplayLeftArr(false);
    } else {
      setDisplayLeftArr(true);
    }
  }

  function setInitialActiveSlide() {
    if (!activeSlideId) {
      return;
    }

    const elems = windowRef.current.children;

    for (let i = 0; i < elems.length; i++) {
      if (activeSlideId === elems[i].children[0].id) {
        setScrollToSlide(i);
        break;
      }
    }
  }

  // EVENT HANDLERS
  function onScrollHandler() {
    getRectElements();
    getCurrentSlide();
    setDisplayArrows();
  }

  function onResizeHandler() {
    getRectElements();
    getCurrentSlide();
    getVisibleSlides();
    setDisplayArrows();
    getIsMobile();
  }

  const onArrowLeft = () => {
    setScrollToSlide(currentSlide - visibleSlides - 1);
  };

  const onArrowRight = () => {
    setScrollToSlide(currentSlide + visibleSlides - 1);
  };

  const onPointGuideClickHandler = (i) => {
    setScrollToSlide(i * visibleSlides);
  };

  const initializeCarousel = () => {
    onResizeHandler();
    onScrollHandler();
  };

  // MAIN CONTROLLERS
  useEffect(() => {
    if (window) {
      window.addEventListener("resize", onResizeHandler);
      window.addEventListener("load", initializeCarousel);
    }
    windowRef.current.addEventListener("scroll", onScrollHandler);

    return () => {
      window.removeEventListener("resize", onResizeHandler);
      window.removeEventListener("load", initializeCarousel);
    };
  }, []);

  useEffect(() => {
    onResizeHandler();
    onScrollHandler();
    setInitialActiveSlide();
  }, [activeSlideId, children]);

  const leftArr =
    displayLeftArr && visibleSlides < children.length && !isMobile;

  const rightArr =
    displayRightArr && visibleSlides < children.length && !isMobile;

  return (
    <StyledCarousel
      sticky={sticky}
      pagination={pagination}
      slideSeparation={slideSeparation}
      $breakpoint={breakpoint}
      blurIntersection={blurIntersection}
      visibleSlides={visibleSlides}
      childrenLenght={children.length}
      displayLeftArr={leftArr}
      displayRightArr={rightArr}
      blurWidth={blurWidth}
    >
      <div
        className="carousel"
        ref={carouselRef}
        data-cy={`${className} Carousel`}
      >
        {leftArr && (
          <Arrow
            onClick={onArrowLeft}
            direction="left"
            className="ArrowLeft"
            cyData={`${className} ArrowLeft`}
            breakpoint={breakpoint}
            radius={arrowWidth}
          />
        )}

        {rightArr && (
          <Arrow
            onClick={onArrowRight}
            direction="right"
            className="ArrowRight"
            cyData={`${className} ArrowRight`}
            breakpoint={breakpoint}
            radius={arrowWidth}
          />
        )}

        <div className="content-container">
          <div className="carousel-window" ref={windowRef}>
            {children &&
              Array.isArray(children) &&
              children.filter(Boolean).map((e, i) => (
                <div
                  key={`slide-${i}`}
                  data-cy={`${className} Slide Slide-${i}`}
                  className={`carousel-item-wrapper ${
                    i + 1 >= currentSlide &&
                    i + 1 < currentSlide + visibleSlides
                      ? "visible-slide"
                      : ""
                  } ${i === currentSlide - 1 ? "current-slide" : ""}`}
                >
                  {e}
                </div>
              ))}
          </div>
        </div>
        {pointsNum > maxPointNum || visibleSlides >= children.length || (
          <PointList
            points={pointsNum}
            className={className}
            activePoint={activePoint}
            onClick={onPointGuideClickHandler}
            breakpoint={breakpoint}
            radius={pointsWidth}
          />
        )}

        {leftArr && (
          <div
            className="blur-left"
            style={{
              width: `${blurWidth}px`,
            }}
          />
        )}
        {rightArr && (
          <div
            className="blur-right"
            style={{
              width: `${blurWidth}px`,
            }}
          />
        )}
        <style jsx>{`.carousel {
  position: relative;
  margin: 0 auto;
  position: relative;

  .content-container {
    overflow: hidden;
  }

  .carousel-window {
    overflow-x: scroll;
    scroll-behavior: auto;
    width: 100%;
    display: flex;
    position: relative;
    scrollbar-width: none;

    .carousel-item-wrapper {
      scroll-snap-align: start;
      padding: 2px;

      &.last {
        scroll-snap-align: end;
      }
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }
  }

  .blur-left {
    position: absolute;
    height: 100%;
    width: 30px;
    top: 0;
    left: 0;
    z-index: 3;
    transition: all 100ms linear;
    pointer-events: none;
  }

  .blur-right {
    position: absolute;
    height: 100%;
    width: 30px;
    top: 0;
    right: 0;
    z-index: 3;
    transition: all 100ms linear;
    pointer-events: none;
  }
}
`}</style>
      </div>
    </StyledCarousel>
  );
};

export default Carousel;

Carousel.propTypes = {
  /**
   * {
   *  "info": "Tarjetas o cards del carousel",
   *  "kind": "both",
   *  "beautifulName": "Tarjetas"
   * }
   */
  children: PropTypes.arrayOf(PropTypes.element),
  /**
   * {
   *  "info": "Define la clase CSS custom que se le aplica al componente",
   *  "kind": "both",
   *  "beautifulName": "Estilos custom"
   * }
   */
  className: PropTypes.string,
  /**
   * {
   *  "info": "Número máximo de puntos de guía que se pinta. Si se excede este número no se pintarán los puntos de guía",
   *  "kind": "both",
   *  "beautifulName": "Puntos de guía máximos"
   * }
   */
  maxPointNum: PropTypes.number,
  /**
   * {
   *  "info": "Margen o separación entre las tarjeas del carousel en pixels",
   *  "kind": "both",
   *  "beautifulName": "Separación entre las tarjetas"
   * }
   */
  slideSeparation: PropTypes.number,
  /**
   * {
   *  "info": "Margen o separación entre las tarjeas del carousel en pixels",
   *  "kind": "both",
   *  "beautifulName": "Separación entre las tarjetas"
   * }
   */
  sticky: PropTypes.bool,
  /**
   * {
   *  "info": "Punto de corte en px para pasar a la versión mobile",
   *  "kind": "both",
   *  "beautifulName": "Punto de corte de vista móvil"
   * }
   */
  breakpoint: PropTypes.number,
  /**
   * {
   *  "info": "Booleano que configura el movimiento de las tarjetas en mobile: si se quiere que se posicionen al terminar el scroll o que el movimiento sea continuo",
   *  "kind": "both",
   *  "beautifulName": "Separación entre las tarjetas"
   * }
   */
  pagination: PropTypes.bool,
  /**
   * {
   *  "info": "Radio de las flechas en px",
   *  "kind": "both",
   *  "beautifulName": "Radio de las flechas"
   * }
   */
  arrowRadius: PropTypes.number,
  /**
   * {
   *  "info": "Radio de los puntos de guía en px",
   *  "kind": "both",
   *  "beautifulName": "Radio de puntos"
   * }
   */
  pointRadius: PropTypes.number,
  /**
   * {
   *  "info": "Booleano que indica si se ha de pintar el blur o no",
   *  "kind": "both",
   *  "beautifulName": "Pintar capa blur"
   * }
   */
  blurIntersection: PropTypes.bool,
  /**
   * {
   *  "info": "Array de 3 elementos en formato rgb. Ej: [255, 255, 255] para negro. Importante: si no es en este formato no funciona",
   *  "kind": "both",
   *  "beautifulName": "Color del blur"
   * }
   */
  blurColor: PropTypes.array,
  /**
   * {
   *  "info": "Ancho del blur en pixeles",
   *  "kind": "both",
   *  "beautifulName": "Ancho del blur"
   * }
   */
  blurWidth: PropTypes.number,
  /**
   * {
   *  "info": "Id de elemento activo",
   *  "kind": "both",
   *  "beautifulName": "Id de elemento activo"
   * }
   */
  activeSlideId: PropTypes.string,
  /**
   * {
   *  "info": "Ancho de la flecha en pixeles",
   *  "kind": "both",
   *  "beautifulName": "Ancho de la flecha"
   * }
   */
  arrowWidth: PropTypes.number,
};

Carousel.defaultProps = {
  children: [],
  className: "Carousel",
  maxPointNum: 5,
  slideSeparation: 16,
  sticky: true,
  breakpoint: 1224,
  pagination: false,
  arrowRadius: 12,
  pointRadius: 12,
  blurIntersection: true,
  blurColor: [255, 255, 255],
  blurWidth: 60,
  activeSlideId: undefined,
  arrowWidth: 40,
};
